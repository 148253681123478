import React, { useState, useRef, useEffect, Suspense } from 'react';
import './Home.scss';
import { Tabs } from '@mui/base/Tabs';
import { TabsList } from '@mui/base/TabsList';
import { TabPanel } from '@mui/base/TabPanel';
import { Tab } from '@mui/base/Tab';
import tab1 from '../../assets/slide1home.webp';
import tab2 from '../../assets/tab2.png';
import tab3 from '../../assets/tab3.png';
import tab4 from '../../assets/tab4.png';
import next from '../../assets/Button - Next slide.png';
import prev from '../../assets/Left Arrow.png';
import top from '../../assets/Circle Background top.png';
import bottm from '../../assets/Circle Background bottm.png';
import shopbackground from '../../assets/shopbackground.png';
import slide1 from '../../assets/slide1home.webp';
import slide2 from '../../assets/slide2.png';
import slide3 from '../../assets/slide3.png';
import slide4 from '../../assets/slide4.png';
import smilyface from '../../assets/smilyface.png';
import doctor from '../../assets/doctor.png';
import pills from '../../assets/pills.png';
import test from '../../assets/test.png';
import pierre from '../../assets/pierre.png';
import pierrephone from '../../assets/pierrePhone.png';
import comf from '../../assets/comfort.png';
import icon1 from '../../assets/featureIcon.png';
import icon2 from '../../assets/Featureicon2.png';
import icon3 from '../../assets/Featureicon3.png';
import icon4 from '../../assets/Featureicon4.png';
import DoctorwithPat from '../../assets/DoctorwithPat.png';
import prescription from '../../assets/prescription.png';
import meds from '../../assets/meds.png';
import hands from '../../assets/hand.png';
import DoctorandPaitient from '../../assets/DoctorandPaitient.png';
import cart from '../../assets/cartIcon.svg';
import { Link } from 'react-router-dom';
import OurProductsSwiper from '../../components/HomeComponents/OurProductsSwiper';
import TestimonialsSwiper from '../../components/HomeComponents/TestimonialsSwiper';
// import covid from '../../assets/covid.svg';
// import health from '../../assets/health.png';
import hearthands from '../../assets/hearthand.png';
import doctorreading from '../../assets/readingpresc.png';
import HomeCategoryProductsSwiper from '../../components/HomeComponents/HomeCategoryProductsSwiper';
// import Appointment from '../../components/Appointment';
import { Button, useMediaQuery } from '@mui/material';
import authorimg from '../../assets/author.png';
import Anne from '../../assets/Anne Rappe.png';
import LJ from '../../assets/LJ.png';
import david from '../../assets/David Brooks.png';
import Andrea from '../../assets/Andrea Hicks.png';
import { motion, animate } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { domain } from '../../config';
import { InView } from 'react-intersection-observer';
import tab5 from '../../assets/taNew5.webp';
// import SectionTwelve from '../../components/HomeComponents/SectionTwelve';
// import PreloadImage from '../../components/LazyImage';
const SectionTwelve = React.lazy(() =>
  import('../../components/HomeComponents/SectionTwelve')
);
const LazyImage = React.lazy(() => import('../../components/LazyImage'));

const Home = () => {
  const [value, setvalue] = useState(1);
  // const [showForm, setshowForm] = useState(false);
  const [isInView, setIsInView] = useState([]);
  const Desktop = useMediaQuery('(min-width:1268px)');

  const switchTab = (move) => {
    if (move === 'forward' && value < 5) {
      setvalue((current) => current + 1);
    }
    if (move === 'backwards' && value > 1) {
      setvalue((current) => current - 1);
    }
  };

  const handleInView = (SectionName, InView) => {
    if (InView) {
      setIsInView((isInView) => [...isInView, SectionName]);
    }
  };
  const testimonail = [
    {
      note: "City Pharmacy is an independent pharmacy that offers its customers that small-town, friendly service that a big pharmacy just can't do. I got my covid-19 vaccination at City Pharmacy. They got back to me when one of the big chains didn't.",
      author: 'Anne Rappe',
      photo: Anne,
      stars: 5,
    },
    {
      note: 'I have been a client of Remedy RX for 20 years beginning when I lived nearby.  I moved a considerable distance away but could not divest myself from their dedicated personal service. I am loath to have my pharmacy needs addressed by anyone else.  I will not go anywhere else.Larry Linton',
      author: 'L&J',
      photo: LJ,
      stars: 5,
    },
    {
      note: "City Pharmacy Remedy's Rx was our neighbourhood pharmacy for many years and still is even though we've moved a few kilometres along Danforth Avenue. Their service is always prompt and personal, and we rely on them for all our medications.",
      author: 'Kit Moore',
      photo: authorimg,
      stars: 5,
    },
    {
      note: 'City Pharmacy is the best drug store in the city.  I will go out of my way to go there. The staff is thorough and responsive,  and they make you feel well taken care of. They are always friendly - you never feel like an anonymous cog in a wheel.',
      author: 'Robin Payne',
      photo: authorimg,
      stars: 5,
    },
    {
      note: "City Pharmacy has been our pharmacy for more years than we can remember. It's wonderful dealing with a small store where staff know their customers. Service has always been friendly, competent and first-rate. Thanks, City Pharmacy, for all these years!",
      author: 'Wyley Powell',
      photo: authorimg,
      stars: 5,
    },
    {
      note: 'Wow, what a great experience.  The staff was super friendly and professional.  Everything was efficient and I would definitely come back.  Really wonderful staff.  Thank you!!!!',
      author: 'David Brooks',
      photo: david,
      stars: 5,
    },
    {
      note: 'Phenomenal service, clean store, friendly, professional staff! I came recommended by family and have referred many more since.',
      author: 'Andrea Hicks',
      photo: Andrea,
      stars: 5,
    },
    {
      note: 'They were extremely easy and pleasant to deal with. I have advised my neighbours and friends to check them out.',
      author: 'Anne Zepotoczny',
      photo: authorimg,
      stars: 5,
    },
    {
      note: 'I have been a customer of City Pharmacy for 30 years.  Service is always excellent.',
      author: 'Helen Caulfield',
      photo: authorimg,
      stars: 5,
    },
    {
      note: 'A wonderful neighbourhood boutique family friendly pharmacy with pleasant helpful staff.',
      author: 'william white',
      photo: authorimg,
      stars: 5,
    },
  ];

  function Counter({ from, to }) {
    const nodeRef = useRef();
    useEffect(() => {
      const node = nodeRef.current;

      const controls = animate(from, to, {
        duration: 1,
        onUpdate(value) {
          node.textContent = value.toFixed(0);
        },
      });

      return () => controls.stop();
    }, [from, to]);

    return <span ref={nodeRef} />;
  }

  return (
    <div className="HomeWrapper">
      <Helmet>
        <meta charSet="utf-8" />
        <title>City Pharmacy | The first to Offer COVID-19 Vaccines</title>
        <link rel="canonical" href={`${domain}/`} />
      </Helmet>
      {/* <Appointment open={showForm} handleClose={() => setshowForm(false)} /> */}
      <div className="SectionOne">
        <div className="tabs-outer">
          <Tabs
            value={value}
            className="wrapper"
            aria-label="basic tabs example"
          >
            <TabsList
              aria-label="lab API tabs example"
              className="maintabs"
              onClick={(e) => setvalue(e.target.tabIndex)}
            >
              <Tab value={1} tabIndex={1} label="Item One">
                <div className="tabtitle">
                  <img src={tab1} alt="icon" />
                  <span className="txt">Stay Protected, Wherever You Go</span>
                  <div className="customDivider"></div>
                </div>
              </Tab>
              <Tab value={2} tabIndex={2} label="Item Four">
                <div className="tabtitle">
                  <img src={tab4} alt="icon1" />
                  <span className="txt">
                    {' '}
                    Decades of Trust in Every Prescription.
                  </span>

                  <div className="customDivider"></div>
                </div>
              </Tab>

              {/* <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{ alignSelf: 'center' }}
              /> */}

              <Tab value={3} tabIndex={3} label="Item Two">
                <div className="tabtitle">
                  <img src={tab2} alt="icon1" />

                  <span className="txt">
                    Expert Care for Everyday Health Concerns
                  </span>

                  <div className="customDivider"></div>
                </div>
              </Tab>
              {/* <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{ alignSelf: 'center' }}
              /> */}
              <Tab value={4} tabIndex={4} label="Item Three">
                <div className="tabtitle">
                  <img src={tab3} alt="icon1" />
                  <span className="txt">Fast, Reliable COVID-19 Testing</span>
                  <div className="customDivider"></div>
                </div>
              </Tab>
              <Tab value={5} tabIndex={5} label="Item Three">
                <div className="tabtitle">
                  <img src={tab5} alt="icon1" />
                  <span className="txt">
                    {' '}
                    Vaccines Your Shield Against Disease
                  </span>
                </div>
              </Tab>

              {/* <Divider
                orientation="vertical"
                variant="middle"
                flexItem
                style={{ alignSelf: 'center' }}
              /> */}
            </TabsList>
            <TabPanel value={1} className="tab-body">
              <div className="body-wrapper">
                <div
                  // initial={{ opacity: 0, x: Desktop ? 300 : 50 }}
                  // whileInView={{ opacity: 1, x: 0 }}
                  // viewport={{ once: true, amount: 0.8 }}
                  // transition={{ ease: 'easeOut', duration: 0.4 }}
                  className="left"
                >
                  <p className="subheader">
                    Travel Vaccine <hr />
                  </p>
                  <p className="header">
                    Your Passport to <br /> Healthier Travel{' '}
                  </p>
                  <p className="note">
                    Get personalized, itinerary-specific consultations and the
                    necessary vaccinations and medications for a safe and
                    healthy trip. Trust in our guidance for a worry-free
                    adventure.
                  </p>
                  {Desktop ? (
                    <Link className="cta" to="/Travel">
                      Book Your Travel Vaccine Appointment
                    </Link>
                  ) : (
                    <Link className="cta" to="/Travel">
                      Book Your Travel Consult
                    </Link>
                  )}
                  {Desktop && (
                    <div className="buttons">
                      <img
                        src={prev}
                        alt="arrow"
                        onClick={() => switchTab('backwards')}
                        style={{ opacity: '0.5' }}
                      />
                      <img
                        src={next}
                        alt="arrow"
                        onClick={() => switchTab('forward')}
                      />
                    </div>
                  )}
                </div>
                <div
                  // initial={{ opacity: 0, scale: 0.7 }}
                  // whileInView={{ opacity: 1, scale: 1 }}
                  // viewport={{ once: true, amount: 0.8 }}
                  // transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                  className="right"
                >
                  <img
                    src={top}
                    alt="background"
                    style={{ zIndex: '1' }}
                    className="background top"
                  />
                  {/* <PreloadImage src={slide1} /> */}
                  {/* <Suspense fallback={<div>Loading...</div>}>
                    <LazyImage src={slide1} alt="Image" />
                  </Suspense> */}
                  {Desktop ? (
                    <img
                      src={slide1}
                      alt="main-img"
                      style={{ zIndex: '16', position: 'relative' }}
                    />
                  ) : (
                    <div className="whiteBG">
                      <img
                        src={slide1}
                        alt="main-img"
                        style={{ zIndex: '16', position: 'relative' }}
                      />
                    </div>
                  )}
                </div>
                <img
                  src={bottm}
                  alt="background"
                  style={{ zIndex: Desktop ? '2' : '1' }}
                  className="background bottm"
                />
              </div>
            </TabPanel>
            <TabPanel value={2} className="tab-body">
              <div className="body-wrapper">
                <div className="left">
                  <p className="subheader">
                    Round-the-Clock Rx <hr />
                  </p>
                  <p className="header">
                    Fill Your Prescription Anytime, Anywhere.{' '}
                  </p>
                  <Link
                    className="cta"
                    to="/Fillprescription"
                    style={{
                      marginBottom: '0',
                      width: '-webkit-fill-available',
                    }}
                  >
                    Submit Your Prescription Today
                  </Link>
                  <div className="lastSlideCTA">
                    <Link className="reorder" to="/Refillprescription">
                      Reorder Your Medications
                    </Link>
                    <Link className="switch" to="/Transferprescription">
                      {' '}
                      <img src={smilyface} alt="smilyface" /> Switch to Trusted
                      Care
                    </Link>
                  </div>
                  {Desktop && (
                    <div className="buttons">
                      <img
                        src={prev}
                        alt="arrow"
                        onClick={() => switchTab('backwards')}
                      />
                      <img
                        src={next}
                        alt="arrow"
                        onClick={() => switchTab('forward')}
                        style={{ opacity: '0.5' }}
                      />
                    </div>
                  )}
                </div>
                <div className="right">
                  <img
                    src={top}
                    alt="background"
                    style={{ zIndex: '1' }}
                    className="background top"
                  />
                  <img
                    src={slide4}
                    alt="first slide"
                    style={{ zIndex: '16', position: 'relative' }}
                  />
                </div>
                <img
                  src={bottm}
                  alt="background"
                  style={{ zIndex: Desktop ? '2' : '1' }}
                  className="background bottm"
                />
              </div>
            </TabPanel>
            <TabPanel value={3} className="tab-body">
              <div className="body-wrapper">
                <div className="left">
                  <p className="subheader">
                    Minor Ailments <hr />
                  </p>
                  <p className="header">
                    Expert Care for Everyday Health Concerns{' '}
                  </p>
                  <Link className="cta" to="/MinorAliments">
                    Consult with Our Pharmacists Now
                  </Link>
                  {Desktop && (
                    <div className="buttons">
                      <img
                        src={prev}
                        alt="arrow"
                        onClick={() => switchTab('backwards')}
                      />
                      <img
                        src={next}
                        alt="arrow"
                        onClick={() => switchTab('forward')}
                      />
                    </div>
                  )}
                </div>
                <div className="right">
                  <img
                    src={top}
                    alt="background"
                    style={{ zIndex: '1' }}
                    className="background top"
                  />
                  <img
                    src={slide2}
                    alt="first slide"
                    style={{ zIndex: '16', position: 'relative' }}
                  />
                </div>
                <img
                  src={bottm}
                  alt="background"
                  style={{ zIndex: Desktop ? '2' : '1' }}
                  className="background bottm"
                />
              </div>{' '}
            </TabPanel>
            <TabPanel value={4} className="tab-body">
              <div className="body-wrapper">
                <div className="left">
                  <p className="subheader">
                    COVID-19 PCR test <hr />
                  </p>
                  <p className="header">Fast, Reliable COVID-19 Testing </p>
                  <Link className="cta" to="/Testing">
                    Schedule Your PCR Test Today
                  </Link>
                  {Desktop && (
                    <div className="buttons">
                      <img
                        src={prev}
                        alt="arrow"
                        onClick={() => switchTab('backwards')}
                      />
                      <img
                        src={next}
                        alt="arrow"
                        onClick={() => switchTab('forward')}
                      />
                    </div>
                  )}
                </div>
                <div className="right">
                  <img
                    src={top}
                    alt="background"
                    style={{ zIndex: '1' }}
                    className="background top"
                  />
                  <img
                    src={slide3}
                    alt="first slide"
                    style={{ zIndex: '16', position: 'relative' }}
                  />
                </div>
                <img
                  src={bottm}
                  alt="background"
                  style={{ zIndex: Desktop ? '2' : '1' }}
                  className="background bottm"
                />
              </div>
            </TabPanel>
            <TabPanel value={5} className="tab-body">
              <div className="body-wrapper">
                <div className="left">
                  <p className="subheader">
                    Vaccine <hr />
                  </p>
                  <p className="header">Stay Protected: Explore Our Vaccines</p>
                  <Link className="cta" to="/Testing">
                    Book Your Vaccine Now{' '}
                  </Link>
                  {Desktop && (
                    <div className="buttons">
                      <img
                        src={prev}
                        alt="arrow"
                        onClick={() => switchTab('backwards')}
                      />
                      <img
                        src={next}
                        alt="arrow"
                        onClick={() => switchTab('forward')}
                      />
                    </div>
                  )}
                </div>
                <div className="right">
                  <img
                    src={top}
                    alt="background"
                    style={{ zIndex: '1' }}
                    className="background top"
                  />
                  <img
                    src={tab5}
                    alt="first slide"
                    style={{ zIndex: '16', position: 'relative' }}
                  />
                </div>
                <img
                  src={bottm}
                  alt="background"
                  style={{ zIndex: Desktop ? '2' : '1' }}
                  className="background bottm"
                />
              </div>
            </TabPanel>
          </Tabs>
          {!Desktop && (
            <div className="buttons">
              <img
                src={prev}
                alt="arrow"
                onClick={() => switchTab('backwards')}
              />
              <img
                src={next}
                alt="arrow"
                onClick={() => switchTab('forward')}
              />
            </div>
          )}
        </div>
      </div>
      <div className="SectionTwo">
        <a href="#shop">Shop</a>
        <a href="#Prescription">Prescription</a>
        <a href="#Minoraliments">Minor Aliments</a>
        <a href="#deals">Exclusive Deals</a>
        <a href="#testing">Testing & Vaccinations</a>
        <a href="#about">About</a>
        <a href="#features">Features</a>
        <a href="#consult">Pharmacy Consultation</a>
        <a href="#testimonials">Testimonials</a>
      </div>

      <div className="SectionThree" id="shop">
        <motion.div
          initial={{ opacity: 0, y: 80 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true, amount: 0.8 }}
          transition={{ ease: 'easeOut', duration: 0.4 }}
          className="top"
        >
          <p className="subheader">shop now</p>
          <p className="header">Shop at City Pharmacy</p>
          <p className="note">
            Discover our range of products, curated for your health and
            well-being.
          </p>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.7 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true, amount: Desktop ? 0.8 : 0.5 }}
          transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
          className="bottom"
        >
          <InView
            as="div"
            onChange={(inView) => handleInView('SectionThree', inView)}
            triggerOnce
          >
            {isInView.includes('SectionThree') && <OurProductsSwiper />}
          </InView>

          <Link className="CTAbutton" to="/shop">
            Shop Now!
          </Link>
        </motion.div>
        <img
          src={shopbackground}
          alt="background"
          style={{ zIndex: '1' }}
          className="background"
        />
      </div>
      {isInView.includes('SectionThree') && (
        <div className="SectionFour" id="Prescription">
          <div className="wrap">
            <div className="top">
              <p className="subheader">Prescription Services</p>
              <p className="header">
                Prescriptions Filled 24/7: <br />
                Your Health, On Your Time.
              </p>
              <p className="greenSubheader">
                Enjoy Complimentary Prescription Delivery to All of Greater
                Toronto!
              </p>
              <p className="note">
                We ensure every step is smooth and personalized just for you.
              </p>
            </div>
            <div className="bottom">
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: Desktop ? 0.8 : 0.5 }}
                transition={{ ease: 'easeOut', duration: 0.4 }}
                className="box"
              >
                <img src={doctor} alt="doctor" />
                <div className="text">
                  <span>Available 24/7 </span>
                  <p className="title">New Prescription? </p>
                  <p className="note">
                    Submit your new prescription online, and let us handle the
                    rest. Experience the convenience of modern healthcare with a
                    personal touch.
                  </p>
                  <Link className="button" to="/Fillprescription">
                    Fill Your Prescription
                  </Link>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: Desktop ? 0.8 : 0.5 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                className="box"
              >
                <img src={pills} alt="doctor" />
                <div className="text">
                  <p className="title">Never miss a dose again! </p>
                  <p className="note">
                    With our quick and convenient refills, your medications are
                    always ready when you need them.
                  </p>
                  <Link className="button white" to="/Refillprescription">
                    Refill Now
                  </Link>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: Desktop ? 0.8 : 0.5 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
                className="box"
              >
                <img src={prescription} alt="doctor" />
                <div className="text">
                  <p className="title">Are you looking for a change?</p>
                  <p className="note">
                    Transfer your prescription to City Pharmacy and discover a
                    world of caring, efficient, and hassle-free service.
                  </p>
                  <Link className="button white" to="/Transferprescription">
                    Transfer Now
                  </Link>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      )}
      {isInView.includes('SectionThree') && (
        <div className="SectionFive" id="Minoraliments">
          <div className="wrap">
            <motion.img
              initial={{ opacity: 0, scale: 0.7 }}
              whileInView={{ opacity: 1, scale: 1 }}
              viewport={{ once: true, amount: 0.8 }}
              transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
              src={DoctorandPaitient}
              alt="doctor and paitient"
            />
            <motion.div
              initial={{ opacity: 0, x: Desktop ? -300 : -50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true, amount: Desktop ? 0.8 : 0.5 }}
              transition={{ ease: 'easeOut', duration: 0.4 }}
              className="textbox"
            >
              <p className="subheader">Expertise in Minor Ailments</p>
              <p className="header">
                Your Trusted Source for <br />
                19 Common Conditions
              </p>
              <p className="note">
                Experience swift and precise care at City Pharmacy. Our seasoned
                pharmacists evaluate and prescribe treatments for 19 common
                conditions, ensuring you're promptly on the path to wellness.
              </p>
              <p className="question">Experiencing Symptoms?</p>
              <Link className="button" to="/MinorAliments">
                Let Us Help
              </Link>
            </motion.div>
          </div>
          <img src={shopbackground} alt="dots" className="topImage" />
          <img src={bottm} alt="dots" className="bottomImage" />
        </div>
      )}
      <hr className="hr" />
      <InView
        as="div"
        onChange={(inView) => handleInView('SectionSix', inView)}
        triggerOnce
      >
        {isInView.includes('SectionSix') && (
          <div className="SectionSix" id="deals">
            <div className="top">
              <div className="text">
                <motion.p
                  initial={{ opacity: 0, x: Desktop ? 300 : 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                  className="subheader"
                >
                  Shop now
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: Desktop ? 300 : 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                  className="header"
                >
                  Exclusive Deals & Savings
                </motion.p>
                <motion.p
                  initial={{ opacity: 0, x: Desktop ? 300 : 50 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
                  className="note"
                >
                  Elevate Your Health, Not Your Spend
                </motion.p>
              </div>
              <Link className="button" to="/shop">
                Shop Now <img src={cart} alt="Cart Icon" />
              </Link>
            </div>
            <div className="bottom">
              {/* <div className="left">
            <p className="heading">Special Offers</p>
            <div className="boxesWrapper">
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                className="box"
              >
                <img src={covid} alt="productImage" />
                <p className="title">
                  New Arrivals
                  <span>Find your medicine</span>
                  <Link className="button" to="/shop">
                    Explore More
                  </Link>
                  <p className="badge">
                    30% <br />
                    OFF
                  </p>
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 0.6 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
                className="box green"
              >
                <img src={health} alt="productImage" />
                <p className="title">
                  New Arrivals
                  <span>Find your medicine</span>
                  <Link className="button" to="/shop">
                    Explore Now
                  </Link>
                  <p className="badge">
                    Up To
                    <br />
                    50%
                  </p>
                </p>
              </motion.div>
            </div>
          </div> */}
              <div
                // initial={{ opacity: 0, x: Desktop ? -300 : -50 }}
                // whileInView={{ opacity: 1, x: 0 }}
                // viewport={{ once: true, amount: 0.8 }}
                // transition={{ ease: 'easeOut', duration: 0.4 }}
                className="right"
              >
                <HomeCategoryProductsSwiper />
              </div>
            </div>
          </div>
        )}
      </InView>
      {isInView.includes('SectionThree') && (
        <>
          <div className="SectionSeven" id="testing">
            <div className="wrap">
              <motion.img
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                src={test}
                alt="test image"
              />
              <motion.div
                initial={{ opacity: 0, x: Desktop ? -300 : -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4 }}
                className="text"
              >
                <p className="subheader">Testing</p>
                <p className="header">
                  Quick and Reliable COVID-19 & Vitamin D Tests at Your
                  Fingertips
                </p>
                <p className="note">
                  Prioritize your health with City Pharmacy efficient COVID-19
                  and Vitamin D testing services and more.
                </p>
                <Link className="button" to="/Testing">
                  Explore More
                </Link>
              </motion.div>
            </div>
            <img src={shopbackground} alt="dots" className="topImage" />
            <img src={bottm} alt="dots" className="bottomImage" />
          </div>
          <div className="SectionEight">
            <div className="wrap">
              <motion.div
                initial={{ opacity: 0, x: Desktop ? 300 : 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.5 }}
                transition={{ ease: 'easeOut', duration: 0.4 }}
                className="leftSide"
              >
                <div className="text">
                  <p className="subheader">Vaccinations at City Pharmacy</p>
                  <p className="header">
                    Shielding You with Expert, Caring Vaccination Services
                  </p>
                </div>
                <div className="whitebox">
                  <p className="title">Protective Shots, Proactive Health</p>
                  <p className="note">
                    Stay a step ahead with City Pharmacy. Safeguard yourself and
                    your loved ones with our range of vaccinations. Your health
                    is our priority.
                  </p>
                  <Link className="button green" to="/Vaccinations">
                    Explore Vaccination Options
                  </Link>
                </div>
                <div className="whitebox">
                  <p className="title">Travel with Confidence</p>
                  <p className="note">
                    Setting off for work, leisure, or volunteering? Protect your
                    health with City Pharmacy's travel vaccines against
                    infections like typhoid or hepatitis A.
                  </p>
                  <Link className="button" to="Travel">
                    Get Travel Protection
                  </Link>
                </div>
              </motion.div>
              <motion.img
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 0.5 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                src={meds}
                alt="meds"
              />
            </div>
          </div>
          <div className="SectionSeven">
            <div className="wrap">
              <motion.img
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                src={comf}
                alt="test image"
              />
              <motion.div
                initial={{ opacity: 0, x: Desktop ? -300 : -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4 }}
                className="text"
              >
                <p className="subheader">
                  Expert Compression Therapy Solutions
                </p>
                <p className="header">Step into Comfort</p>
                <p className="note">
                  Experience relief and support with City Pharmacy's specialized
                  compression stockings. From precise fitting to choosing the
                  right style, we guide you every step of the way. 
                </p>
                <Link className="button green" to="/Compression">
                  Start Compression Comfort
                </Link>
              </motion.div>
            </div>
            <img
              src={top}
              alt="background"
              style={{ zIndex: '1' }}
              className="background"
            />
          </div>
          <div className="SectionFive" id="about">
            <div className="wrap">
              <img
                src={Desktop ? pierre : pierrephone}
                alt="doctor and paitient"
              />
              <div className="textbox">
                <p className="subheader">About City Pharmacy</p>
                <p className="header">
                  <Counter from={0} to={4} /> Decades of Dedication, Evolving
                  for You.
                </p>
                <p className="note">
                  City Pharmacy stands as a trusted community cornerstone,
                  blending tradition with modern care. From pioneering health
                  initiatives like the COVID-19 vaccine to embracing digital
                  advancements, our focus remains on your health and our
                  commitment.
                </p>
                <Link className="button" to="/About">
                  Discover Our Story
                </Link>
              </div>
            </div>
          </div>
          <hr className="hr" />
          <div className="SectionNine" id="features">
            <div className="wrap">
              {' '}
              <div className="upper">
                <p className="subheader">City Pharmacy Features</p>
                <p className="header">
                  Experience a Modern Pharmacy with <br /> Traditional Values.
                </p>
              </div>
              <div className="lower">
                <div className="box">
                  <motion.img
                    initial={{ opacity: 0, x: Desktop ? -50 : -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    src={icon1}
                    alt="icon"
                  />
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Personalized Care
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Every patient is unique. Our pharmacists take the time to
                    understand your needs and provide tailored advice.
                  </motion.p>
                </div>
                <div className="box">
                  <motion.img
                    initial={{ opacity: 0, x: Desktop ? -50 : -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    src={icon2}
                    alt="icon"
                  />
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Digital Services
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Seamless health solutions: From online refills to digital
                    consultations, experience modern convenience
                  </motion.p>
                </div>
                <div className="box">
                  <motion.img
                    initial={{ opacity: 0, x: Desktop ? -50 : -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    src={icon3}
                    alt="icon"
                  />
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Community Trust
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Serving the community for decades, we've earned the trust
                    and loyalty of generations.
                  </motion.p>
                </div>
                <div className="box">
                  <motion.img
                    initial={{ opacity: 0, x: Desktop ? -50 : -30 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    src={icon4}
                    alt="icon"
                  />
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Expertise & Training
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Our continuously trained team ensures you receive the latest
                    healthcare advice and services.
                  </motion.p>
                </div>
              </div>
            </div>
          </div>
          <hr className="hr" />
          <div className="SectionTen" id="consult">
            <div className="wrap">
              <motion.div
                initial={{ opacity: 0, x: Desktop ? 300 : 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4 }}
                className="textbox"
              >
                <p className="subheader">Expert Pharmacy Consultations</p>
                <p className="header">
                  Personalized Advice for Your Health & Wellness
                </p>
                <p className="note">
                  Navigate your health journey confidently. From medication
                  management to travel advice, our pharmacists are here to
                  provide tailored guidance every step of the way.
                </p>
                <Link className="button green" to="/Consult_a_pharmacist">
                  Consult with Experts
                </Link>
              </motion.div>
              <motion.img
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                src={DoctorwithPat}
                alt="doctor and paitient"
              />
            </div>
            <img
              src={top}
              alt="background"
              style={{ zIndex: '1' }}
              className="background"
            />
          </div>
        </>
      )}
      <InView
        as="div"
        style={{ width: '100%' }}
        onChange={(inView) => handleInView('SectionEleven', inView)}
        triggerOnce
      >
        {isInView.includes('SectionEleven') && (
          <div className="SectionEleven" id="testimonials">
            <div className="wrap">
              {' '}
              <motion.div
                initial={{ opacity: 0, y: 100 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4 }}
                className="upper"
              >
                <p className="subheader">Reviews</p>
                <p className="header">
                  Hear It From Our Community. <br />
                  Voices of Health
                </p>
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0.7 }}
                whileInView={{ opacity: 1, scale: 1 }}
                viewport={{ once: true, amount: 0.8 }}
                transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                className="lower"
              >
                <TestimonialsSwiper testimonail={testimonail} count="3" />
              </motion.div>
            </div>
          </div>
        )}
      </InView>
      {/* <InView
        as="div"
        onChange={(inView) => handleInView('SectionTwilve', inView)}
        triggerOnce
      > */}
      {isInView.includes('SectionEleven') && <SectionTwelve />}
      {/* </InView> */}
      {isInView.includes('SectionThree') && (
        <>
          <hr className="hr" />
          <div className="SectionThirten">
            <div className="wrap">
              {' '}
              <div className="upper">
                <p className="subheader">Contact us</p>
                <p className="header">Stay Connected with City Pharmacy</p>
              </div>
              <div className="lower">
                <div className="box">
                  <motion.svg
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <path
                      d="M16.291 19.5664C20.7093 19.5664 24.291 15.9847 24.291 11.5664C24.291 7.14813 20.7093 3.56641 16.291 3.56641C11.8727 3.56641 8.29102 7.14813 8.29102 11.5664C8.29102 15.9847 11.8727 19.5664 16.291 19.5664Z"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M20.9267 18.0879L22.9574 30.2346L16.2907 26.2346L9.62402 30.2346L11.6547 18.0879"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Accreditation
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Number: 22335
                  </motion.p>
                </div>
                <div className="box">
                  <motion.svg
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <path
                      d="M25.6247 28.9004V26.2337C25.6247 24.8192 25.0628 23.4627 24.0626 22.4625C23.0624 21.4623 21.7058 20.9004 20.2913 20.9004H12.2913C10.8769 20.9004 9.5203 21.4623 8.5201 22.4625C7.51991 23.4627 6.95801 24.8192 6.95801 26.2337V28.9004"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.2913 15.5671C19.2369 15.5671 21.6247 13.1792 21.6247 10.2337C21.6247 7.28821 19.2369 4.90039 16.2913 4.90039C13.3458 4.90039 10.958 7.28821 10.958 10.2337C10.958 13.1792 13.3458 15.5671 16.2913 15.5671Z"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Ownership & Management
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Owner & Designated Manager: <br /> Pierre Nasralla
                  </motion.p>
                </div>
                <div className="box">
                  <motion.svg
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <path
                      d="M26.9574 14.2331C26.9574 22.2331 16.2907 30.2331 16.2907 30.2331C16.2907 30.2331 5.62402 22.2331 5.62402 14.2331C5.62402 11.4041 6.74783 8.69099 8.74822 6.6906C10.7486 4.69021 13.4617 3.56641 16.2907 3.56641C19.1197 3.56641 21.8328 4.69021 23.8332 6.6906C25.8335 8.69099 26.9574 11.4041 26.9574 14.2331Z"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.291 18.2344C18.5002 18.2344 20.291 16.4435 20.291 14.2344C20.291 12.0252 18.5002 10.2344 16.291 10.2344C14.0819 10.2344 12.291 12.0252 12.291 14.2344C12.291 16.4435 14.0819 18.2344 16.291 18.2344Z"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Location
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    238 Danforth Avenue, <br /> Toronto, Ontario M4K 1N4
                  </motion.p>
                </div>
                <div className="box">
                  <motion.svg
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <path
                      d="M29.4372 3.56641L14.7705 18.2331"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M29.4372 3.56641L20.1038 30.2331L14.7705 18.2331L2.77051 12.8997L29.4372 3.56641Z"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Get in Touch
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Phone: 416.469.1898 <br />
                    Fax: 416.469.5464 <br />
                    Email: info@citypharmacy.ca
                  </motion.p>
                </div>
                <div className="box">
                  <motion.svg
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="33"
                    viewBox="0 0 33 33"
                    fill="none"
                  >
                    <path
                      d="M16.7093 30.2331C24.0731 30.2331 30.0426 24.2635 30.0426 16.8997C30.0426 9.53594 24.0731 3.56641 16.7093 3.56641C9.34551 3.56641 3.37598 9.53594 3.37598 16.8997C3.37598 24.2635 9.34551 30.2331 16.7093 30.2331Z"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M16.709 8.90039V16.9004L22.0423 19.5671"
                      stroke="#219D50"
                      strokeWidth="2.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </motion.svg>
                  <motion.p
                    initial={{ opacity: 0, x: -50, y: -40 }}
                    whileInView={{ opacity: 1, x: 0, y: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4, delay: 0.2 }}
                    className="title"
                  >
                    Hours of Operation
                  </motion.p>
                  <motion.p
                    initial={{ opacity: 0, x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true, amount: 0.8 }}
                    transition={{ ease: 'easeOut', duration: 0.4 }}
                    className="note"
                  >
                    Monday to Friday: 10 am - 6 pm <br />
                    Saturday: 11 am - 4 pm <br />
                    Sunday: Closed
                  </motion.p>
                </div>
              </div>
              <Link className="button" to="/Contact">
                Contact Us
              </Link>
            </div>
          </div>
          <div className="SectionFort">
            <div className="wrap">
              <div className="lower">
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: 'easeOut', duration: 0.4 }}
                  className="box"
                >
                  <div className="imagebg">
                    <img src={hands} alt="hands" />
                  </div>
                  <p className="title">
                    Free Prescription Delivery to all of Greater Toronto Area
                  </p>
                  <Link className="button" to="/Fillprescription">
                    Fill Your Prescription
                  </Link>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.3 }}
                  className="box"
                >
                  <div className="imagebg">
                    <img src={doctorreading} alt="doctorreading" />
                  </div>{' '}
                  <p className="title">
                    Effortlessly book, manage, and consult online
                  </p>
                  <Button
                    className="button"
                    as="a"
                    href="https://citypharmacy.medmeapp.ca/schedule/activity/travel-health-consultation"
                    target="_blank"
                  >
                    Book Now
                  </Button>
                </motion.div>
                <motion.div
                  initial={{ opacity: 0, scale: 0.7 }}
                  whileInView={{ opacity: 1, scale: 1 }}
                  viewport={{ once: true, amount: 0.8 }}
                  transition={{ ease: 'easeOut', duration: 0.4, delay: 0.6 }}
                  className="box"
                >
                  <div className="imagebg">
                    <img src={hearthands} alt="hands" />
                  </div>{' '}
                  <p className="title">
                    Trusted health partner for generations
                  </p>
                  <Link className="button" to="/Consult_a_pharmacist">
                    Get Consultation
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Home;
